<template>
  <Modal
    :value="value"
    title="数据同步"
    @input="(val) => $emit('input', val)"
    @on-visible-change="modalShow"
  >
    <Form ref="form" :model="form" :rules="rules" :label-width="100">
      <FormItem label="目标设备" prop="guid">
        <Select v-model="form.guid">
          <Option v-for="item in devices" :value="item.guid" :key="item.guid">{{
            item.name
          }}</Option>
        </Select>
      </FormItem>
      <FormItem label="比率" prop="percentage">
        <Input v-model="form.percentage">
          <span slot="append">%</span>
        </Input>
      </FormItem>
      <FormItem label="备注" prop="remark">
        <Input v-model="form.remark"></Input>
      </FormItem>
    </Form>
    <p slot="footer" style="text-align: center">
      <Button type="primary" :loading="loading" @click="submit">提交</Button>
      <Button @click="() => $emit('input', false)">取消</Button>
    </p>
  </Modal>
</template>

<script>
import CU from "@/common/util";
export default {
  name: "",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    guid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        guid: "",
        renewalGuid: "",
        type: "0",
        percentage: "",
        remark: ''
      },
      rules: {
        guid: [{ required: true, message: "请选择目标设备" }],
        percentage: [
          { required: true, message: "请填写比率" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value, 0, 100)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "请填写正确的比率",
          },
        ],
      },
      devices: [],
      loading: false,
    };
  },
  methods: {
    modalShow(visible) {
      if (visible) {
        this.getList();
      } else {
        this.form = {
          guid: "",
          renewalGuid: "",
          type: "0",
          percentage: "",
          remark: ''
        };
        this.$refs.form.resetFields();
      }
    },
    getList() {
      this.$post(this.$api.BUSSDEVICESENSOR.LIST, {
        pageNo: 1,
        pageSize: 999,
      }).then((res) => {
        this.devices = res.list.filter((item) => item.guid != this.guid);
      });
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.renewalGuid = this.guid;
        params.percentage = this.form.percentage / 100;
        this.loading = true;
        this.$post(this.$api.BUSSDEVICESENSOR.DATA_SYNC, params)
          .then(() => {
            this.$emit("sync-success");
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>